import React, { useEffect, useState} from "react";
import {Step, Stepper} from "react-form-stepper";
import {Form, Modal} from "react-bootstrap";
import StepWizard from "react-step-wizard";
import {Button, FormGroup, Label} from "reactstrap";
import Referencement from "./referencement";
import {Controller, useForm} from "react-hook-form";
import {Autocomplete, TextField} from "@mui/material";
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import axios from "axios";
import {wait} from "@testing-library/user-event/dist/utils";
import {withHookFormMask} from "use-mask-input";
import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';



const ActionButtons = (props) => {
    
    const handleBack = () => {
        props.previousStep();
    };

    const handleNext = () => {
        props.nextStep();
    };

    const handleFinish = () => {
        props.lastStep();
    };

    return (
        <div className="col-12">
            <div className="m-4 d-flex justify-content-between">
                {props.currentStep > 1 && (
                    <Button  onClick={handleBack}>Précédent</Button>
                )}
                {props.currentStep < props.totalSteps && (
                    <Button className="btn primary" disabled={!props.stepOK}  type="submit" onClick={handleNext}>Suivant</Button>
                )}
                {props.currentStep === props.totalSteps && (
                    <Button type="submit" className="btn primary" >Envoyer</Button>
                )}
            </div>
        </div>
    );
};

const One = (props) => {
    const [error, setError] = useState("");
    const [search, setSearch] = useState({});
    const [stepOK, setStepOK] = useState(false);

    const validate = () => {
        window.scrollTo(0,0);
        props.nextStep();
        props.userCallback(search);
        setStepOK(true);
    };

    const callback = payload => {
        setSearch(payload)
    }

    const onValidStep = (stateStep) => {
        setStepOK(false);
        setStepOK(stateStep);
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <span style={{ color: "red" }}>{error}</span>
                    <Referencement onStepOk={onValidStep}  callback={callback} />
                </div>
            </div>
            <div className="row">
                <ActionButtons  {...props} stepOK={stepOK} nextStep={validate} />
            </div>
        </div>

    );
};

const Two = (props) => {
    const [stepOne, setStepOne] = useState({});
    const [error, setError] = useState("");
    const { register, handleSubmit,trigger,reset, watch,control, formState: { errors } } = useForm({
        mode: 'onChange'
    });

    const [stepOK, setStepOK] = useState(true);
    const [ape, setApe] = useState('');
    const [natVoie, setNatVoie] = useState('');
    let [typeStructure, setTypeStructure] = useState([]);
    const [searchTypeStructure, setSearchtypeStructure] = useState([]);
    const [searchApe, setSearchApe] = useState([]);
    const [searchNatVoie, setSearchNatVoie] = useState([]);
    const [suggestions, setSuggestions] = useState([]);
    const [suggestionsNatVoie,setSuggestionsNatVoie] = useState([]);
    const [suggestionsTypeStructure,setSuggestionsTypeStructure] = useState([]);
    const [errorPhones, setErrorPhones] = useState("");
       
    useEffect(() => {

        if(props.user){
            let referecingObject = {};
        referecingObject.structureSiret = props.user.siret;
        referecingObject.structureName = props.user.denominationunitelegale || props.user.nomunitelegale;
        referecingObject.structureAcronym = props.user.sigleunitelegale;
        referecingObject.structureApeId = props.user.activiteprincipaleunitelegale;
        referecingObject.structureAddressStreetNumber = props.user.numerovoieetablissement;
        referecingObject.structureAddressStreetTypeId = props.user.typevoieetablissement;
        referecingObject.structureAddressStreetNumberIndex = props.user.indicerepetitionetablissement;
        referecingObject.structureAddressStreetLabel = props.user.libellevoieetablissement;
        referecingObject.structureAddressStreetDetailsInfos = props.user.complementadresseetablissement;
        referecingObject.structureAddressCityPlace = props.user.distributionspecialeetablissement;
        referecingObject.structureAddressCityZip = props.user.codepostaletablissement;
        referecingObject.structureAddressCity = props.user.libellecommuneetablissement;
        referecingObject.structureAddressCountryId = 75;
        referecingObject.structureAddressCityInsee = props.user.codecommuneetablissement;

        if (props.user.sigleunitelegale === undefined) {
            referecingObject.structureAcronym = props.user.enseigne1etablissement;
        }

        if (props.user.geolocetablissement !== undefined) {
            referecingObject.structureAddressLatitude = props.user.geolocetablissement[0];
            referecingObject.structureAddressLongitude = props.user.geolocetablissement[1];
        }

        // reset({ ...serializeProperties(referecingObject) });

        reset({ ...referecingObject });

        }
        
    }, [props.user]);



    const validatePhoneNumbers = () => {
        const phone = watch('structurePhone');
        const mobile = watch('structureMobile');
        return Boolean(phone || mobile) || 'Au moins un des champs téléphones doit être renseigné.';
    };

    const onSuggestHandler = (text) => {
        setApe(text);
        setSuggestions([]);
    }

    const onSuggestHandlerNatVoie = (text) => {
        setNatVoie(text);
        setSuggestionsNatVoie([]);
    }
    const onSuggestHandlerTypeStructure = (type) => {
        setTypeStructure(type);
        setSuggestionsTypeStructure([]);
    }

    const onChangeHandler = (text) => {
        setSearchApe(props.data1);
        let matches = []
        text = text.split('.').join("");
        if (text.length > 0) {
            matches = searchApe.filter(user => {
                const regex = new RegExp(`${text}`, "gi");
                return user.code.match(regex)
            })
        }
        setSuggestions(matches)
        setApe(text)
    }
    const onChangeHandlerNatVoie = (text) => {
        setSearchNatVoie(props.data2)
        let matches = []
        if (text.length > 0) {
            matches = searchNatVoie.filter(user => {
                const regex = new RegExp(`${text}`, "gi");
                return user.name.match(regex)
            })
        }
        setSuggestionsNatVoie(matches)
        setNatVoie(text)
    }
    const onChangeHandlerTypeStructure = (text) => {
        setSearchtypeStructure(props.data3);
        let matches = []
        if (text.length > 0) {
            matches = searchTypeStructure.filter(user => {
                const regex = new RegExp(`${text}`, "gi");
                return user.name.match(regex)
            })
        }
        setSuggestionsTypeStructure(matches)
        setTypeStructure(text)
    }

    const onInputChanged = (event) => {
    };



    const previous = data => {
        setApe({});
        setNatVoie({});
        window.scrollTo(0,0);
        props.previousStep();
    }


    const validate2 = async data => {
        setErrorPhones('');
        await wait(500);
        let newStructure = {...data};
        let date = new Date();
        let options = { day: 'numeric', month: 'numeric', year: 'numeric' };

        newStructure.structureTypeIds = typeStructure;

        if (newStructure.structureApeId !== undefined &&  typeof newStructure.structureApeId === 'string' && newStructure.structureApeId.includes(".")) {
            let filtredApe = props.data1.find(e => e.code === newStructure.structureApeId.split('.').join(""));
            newStructure.structureApeId = filtredApe !== undefined ? filtredApe.id : ape.id;
        }
        else {
            newStructure.structureApeId = ape.id;
        }

        if (newStructure.structureAddressStreetTypeId !== undefined && typeof newStructure.structureAddressStreetTypeId === 'string') {
            let filteredTypeStreetId =  props.data2.find(e => e.name === newStructure.structureAddressStreetTypeId);
            newStructure.structureAddressStreetTypeId = filteredTypeStreetId !== undefined ? filteredTypeStreetId.id : natVoie.id;
        }
        else {
            newStructure.structureAddressStreetTypeId = natVoie.id;
        }

        let num = newStructure.structureAddressStreetNumber;
        if (num !== undefined) {
            newStructure.structureAddressStreetNumber = num.toString();
        }


        if (typeStructure) {
            newStructure.structureTypeIds = typeStructure.map((datum) => {
                return datum.id;
            });
        }
        else {
            newStructure.structureTypeIds = [];
        }

        newStructure["Name"] = "Référencement | " + newStructure.structureName + " - " + date.toLocaleDateString('fr-FR', options);
        newStructure["FormInfos"] = date.toLocaleDateString('fr-FR', options) + " - En envoyant ce formulaire, j'accepte que les informations saisies soient exploitées à usage exclusif de contact pour traitement de ma demande.";

        if (newStructure.structureAddressLatitude === "" && newStructure.structureAddressLongitude === "") {
            newStructure.structureAddressLatitude = null;
            newStructure.structureAddressLongitude = null;
        }

        //console.log("submit",newStructure);

        props.structureCallback(newStructure);

        let isValid = false;
        isValid = await trigger([
            "structureName",
            "structureSiret",
            "structurePhone",
            "structureEmail",
            "structureWebsite",
            "structureMobile",
            "structureAcronym",
            "structureAddressStreetTypeId",
        ]);

        if(isValid) {
            window.scrollTo(0,0);
            setError("");
            props.nextStep();
            setStepOne(newStructure);
        }
    };


    return (
        <div className="container">
            <form onSubmit={handleSubmit(validate2)}>
                <div className="row">
                    <div className="col-12">
                        <FormGroup>
                            <Label>Siret * : </Label>
                            <input
                                {...register('structureSiret', {required: 'Vous devez renseigner ce champ'})}
                                type="text"
                                name="structureSiret"
                                className="form-control"
                                onChange={onInputChanged}
                            />
                            {errors.structureSiret && <div className="text-danger">{errors.structureSiret.message}</div>}
                        </FormGroup>

                        <FormGroup>
                            <Label>Raison Sociale * : </Label>
                            <input
                                {...register('structureName', {required: 'Vous devez renseigner ce champ'})}
                                type="text"
                                name="structureName"
                                className="form-control"
                                onChange={onInputChanged}
                            />
                            {errors.structureName && <span className="text-danger">{errors.structureName.message}</span>}
                        </FormGroup>
                        <FormGroup>
                            <Label>Sigle ou nom commercial (nom que vous souhaitez voir apparaître) * : </Label>
                            <input
                                {...register('structureAcronym', {required: 'Vous devez renseigner ce champ'})}
                                type="text"
                                name="structureAcronym"
                                className="form-control"
                                onChange={onInputChanged}
                            />
                            {errors.structureAcronym && <span className="text-danger">{errors.structureAcronym.message}</span>}

                        </FormGroup>
                        <FormGroup>
                            <Label>Code APE: </Label><br />
                            <span><strong>{ape.code !== undefined && (ape.code + "-" + ape.name)} </strong></span>
                            <input
                                {...register('structureApeId', {required: 'Vous devez renseigner ce champ'})}
                                type="search"
                                name="structureApeId"
                                value={ape.code?? ape.code}
                                className="form-control"
                                onChange={e => onChangeHandler(e.target.value) }

                            />
                            {suggestions && suggestions.map((suggestion, i) =>
                                <div className="suggestion" key={i}
                                     onClick={() => onSuggestHandler(suggestion)}>
                                    {suggestion.code} - {suggestion.name}
                                </div>
                            )}
                            {errors.structureApeId && <span className="text-danger">{errors.structureApeId.message}</span>}
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <FormGroup>
                            <Label>N° de la voie  : </Label>
                            <input
                                {...register('structureAddressStreetNumber')}
                                type="text"
                                readOnly={true}
                                name="structureAddressStreetNumber"
                                autoComplete="randmstreetnumber"
                                className="form-control"
                            />
                            {errors.structureAddressStreetNumber && <span className="text-danger">{errors.structureAddressStreetNumber.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <FormGroup>
                            <Label>Nature de la voie  : </Label><br />
                            <input
                                {...register('structureAddressStreetTypeId')}
                                type="text"
                                readOnly={true}
                                value={natVoie.name?? natVoie.name}
                                name="structureAddressStreetTypeId"
                                autoComplete="randmstreettype"
                                className="form-control"
                                onChange={e => onChangeHandlerNatVoie(e.target.value)}
                            />
                            <span><strong>{(natVoie.name !== undefined) && natVoie.name}</strong></span>
                            <div className="search">
                                {suggestionsNatVoie && suggestionsNatVoie.map((suggestion, i) =>
                                    <div className="suggestion" key={i}
                                         onClick={() => onSuggestHandlerNatVoie(suggestion)}>
                                        {suggestion.name}
                                    </div>
                                )}
                            </div>
                            {errors.structureAddressStreetTypeId && <span className="text-danger">{errors.structureAddressStreetTypeId.message}</span>}
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <FormGroup>
                            <Label>Indice de répétition de la voie : </Label>
                            <input
                                {...register('structureAddressStreetNumberIndex')}
                                type="text"
                                readOnly={true}
                                name="structureAddressStreetNumberIndex"
                                className="form-control"
                                onChange={onInputChanged}
                            />
                        </FormGroup>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <FormGroup>
                            <Label>Libellé de la voie  : </Label>
                            <input
                                {...register('structureAddressStreetLabel')}
                                type="text"
                                readOnly={true}
                                name="structureAddressStreetLabel"
                                autoComplete="randomstreetlabel"
                                className="form-control"
                                onChange={onInputChanged}
                            />
                            {errors.structureAddressStreetLabel && <span className="text-danger">{errors.structureAddressStreetLabel.message}</span>}
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <FormGroup>
                            <Label>Complément : </Label>
                            <input
                                {...register('structureAddressStreetDetailsInfos')}
                                type="text"
                                name="structureAddressStreetDetailsInfos"
                                className="form-control"
                                onChange={onInputChanged}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Lieu dit, boîte postale   : </Label>
                            <input
                                {...register('structureAddressCityPlace')}
                                type="text"
                                name="structureAddressCityPlace"
                                autoComplete="anyrandomcityplace"
                                className="form-control"
                                onChange={onInputChanged}
                            />
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <FormGroup>
                            <Label>Code Postal  : </Label>
                            <input
                                {...register('structureAddressCityZip')}
                                type="text"
                                readOnly={true}
                                name="structureAddressCityZip"
                                autoComplete="anyrandomzipcode"
                                className="form-control"
                                onChange={onInputChanged}
                            />
                            {errors.structureAddressCityZip && <span className="text-danger">{errors.structureAddressCityZip.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <FormGroup>
                            <Label>Ville  : </Label>
                            <input
                                {...register('structureAddressCity')}
                                type="text"
                                readOnly={true}
                                name="structureAddressCity"
                                autoComplete="anyrandomstring"
                                className="form-control"
                                onChange={onInputChanged}
                            />
                            {errors.structureAddressCity && <span className="text-danger">{errors.structureAddressCity.message}</span>}
                        </FormGroup>

                        {/* Hidden Inputs*/}
                        <input autoComplete="on" style={{ display: 'none' }} id="fake-hidden-input-to-stop-google-address-lookup"/>

                        <input
                            {...register('structureAddressCityInsee')}
                            type="hidden"
                            name="structureAddressCityInsee"
                            className="form-control"
                            onChange={onInputChanged}
                        />
                        <input
                            {...register('structureAddressLatitude')}
                            type="hidden"
                            name="structureAddressLatitude"
                            className="form-control"
                            onChange={onInputChanged}
                        />
                        <input
                            {...register('structureAddressLongitude')}
                            type="hidden"
                            name="structureAddressLongitude"
                            className="form-control"
                            onChange={onInputChanged}
                        />
                        <input
                            {...register('structureAddressCountryId')}
                            type="hidden"
                            name="structureAddressCountryId"
                            className="form-control"
                            onChange={onInputChanged}
                        />
                        {/* Hidden Inputs*/}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <FormGroup>
                            <Label><sup>(1)</sup> Téléphone fixe : </Label>
                            <input
                                {...withHookFormMask(register('structurePhone', {validate: validatePhoneNumbers}), ['99 99 99 99 99'])}
                                type="tel"
                                name="structurePhone"
                                className="form-control"
                                onChange={onInputChanged}
                            />
                            {errors.structurePhone && <span className="text-danger">{errors.structurePhone.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <FormGroup>
                            <Label><sup>(1)</sup> Téléphone mobile de la structure  : </Label>
                            <input
                                {...withHookFormMask(register('structureMobile', {validate: validatePhoneNumbers}), ['99 99 99 99 99'])}
                                type="tel"
                                name="structureMobile"
                                className="form-control"
                                onChange={onInputChanged}
                            />
                            {errors.structureMobile && <span className="text-danger">{errors.structureMobile.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="d-flex justify-content-center">
                        <span className="text-danger">{errorPhones}</span>
                    </div>
                    <p>(1) Au moins un des champs téléphones doit être renseigné.</p>
                </div>
                <div className="row">
                    <div className="col-12">
                        <FormGroup>
                            <Label>Email structure * : </Label>
                            <input
                                {...register('structureEmail', {required: 'Vous devez renseigner ce champ', pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "veuillez saisir un email valide"
                                    }})}
                                type="email"
                                name="structureEmail"
                                autoComplete="some-email"
                                className="form-control"
                                onChange={onInputChanged}
                            />
                            {errors.structureEmail && <span className="text-danger">{errors.structureEmail.message}</span>}
                        </FormGroup>
                        <FormGroup>
                            <Label>Site web *   : </Label>
                            <p>Si vous n'avez pas de site web, veuillez noter www.pasdesite.com</p>
                            <input
                                {...register('structureWebsite', {
                                    required: 'Vous devez renseigner ce champ', pattern: {
                                        value: /^(https?:\/\/)?(www\.)?[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/i,
                                        message: "veuillez saisir une URL valide"
                                    }
                                })}
                                {...register('structureWebsite')}
                                type="text"
                                name="structureWebsite"
                                className="form-control"
                                onChange={onInputChanged}
                            />
                            {errors.structureWebsite && <span className="text-danger">{errors.structureWebsite.message}</span>}
                        </FormGroup>
                        <FormGroup>
                            <Label>Description détaillée de votre structure et de votre activité   : </Label><br />
                            <Label>Pour une meilleure instruction de votre demande, nous vous remercions de compléter avec précision cette rubrique. </Label>
                            <textarea
                                {...register('structureActivityDescription')}
                                name="structureActivityDescription"
                                className="form-control"
                                onChange={onInputChanged}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Type de structure : </Label><br />
                            <Controller
                                render={(props) => (
                                    <Autocomplete
                                        {...props}
                                        size="small"
                                        multiple
                                        id="tags-outlined"
                                        defaultValue={[]}
                                        options={searchTypeStructure}
                                        onInputChange={(event, newInputValue) => {
                                            setTypeStructure(newInputValue);
                                        }}
                                        getOptionLabel={(option) => option.name}
                                        filterSelectedOptions
                                        renderInput={(params) => (
                                            <TextField
                                                {...register('structureTypeIds')}
                                                {...params}
                                                label="Type de structure..."
                                                onChange={e => onChangeHandlerTypeStructure(e.target.value) }
                                                onFocus={e => onChangeHandlerTypeStructure(e.target.value)}
                                                placeholder="Type de structure"
                                            />
                                        )}
                                        onChange={(event, newValue) => {
                                            setTypeStructure(newValue);
                                        }}
                                    />
                                )}
                                name="structureTypeIds"
                                control={control}
                            />
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <ActionButtons {...props} stepOK={stepOK} previousStep={previous} nextStep={validate2} />
                    </div>
                </div>
            </form>
        </div>
    );
};

const Three = (props) => {
    const { register, handleSubmit,trigger,control,formState, watch, formState: { errors } } = useForm({
        mode: 'onChange'
    });

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);


    const moreDetail = watch("isSameReferent");
    const adherer = watch("isRequestForMembership");
    const trainning = watch("isRequestForRaphaelCmaformation");
    const [value, setValue] = React.useState(dayjs());
    const [fonction, setFonction] = useState({});
    const [fonctionBis, setFonctionBis] = useState({});

    const [searchFunctions, setSearchFunctions] = useState([]);
    const [suggestionsFunctions, setSuggestionsFunctions] = useState([]);

    const [domaine, setDomaine] = useState('');
    const [searchDomaines, setSearchDomain] = useState([]);
    const [suggestionsDomain, setSuggestionsDomain] = useState([]);

    const [errorPhones, setErrorPhones] = useState("");


    const madame = 1;
    const monsieur = 3;

    const referentMembershipPhone = watch("personReferentMembershipPhone");
    const referentMembershipMobile = watch("personReferentMembershipMobile");


    const handleChange = (newValue) => {
        setValue(newValue);
    };


    const previous = data => {
        window.scrollTo(0,0);
        props.previousStep();
    }

    const handleLastStep =  async data => {

        setErrorPhones(null);
        const result = {...data};
        if (adherer && result.structureMembershipCreationDate) {
            result.personReferentMembershipFunctionId = fonctionBis.id;
        }

        let functionId = fonction.id;

        result.personReferentStructureFunctionId = functionId;


        result.personReferentStructureTitleId = parseInt(result.personReferentStructureTitleId);

        result.structureOfferAccomodation = result.structureOfferAccomodation === "true";

        result.structureOfferRestoration = result.structureOfferRestoration === "true";

        result.isSameReferent = result.isSameReferent === "true";

        let isValid = false;

        if (result.isSameReferent === true) {

            result.personReferentMembershipFirstname = result.personReferentStructureFirstname
            result.personReferentMembershipLastname = result.personReferentStructureLastname
            result.personReferentMembershipEmail = result.personReferentStructureEmail
            result.personReferentMembershipPhone = result.personReferentStructurePhone
            result.personReferentMembershipMobile = result.personReferentStructureMobile
            result.personReferentMembershipFunctionId = result.personReferentStructureFunctionId
            result.personReferentMembershipTitleId = result.personReferentStructureTitleId

        }
        //} else {
        //    result.isSameReferent = false
        //}

        isValid = await trigger();

        
    function serializeProperties(obj) {
        const serializedObj = {};

        
        // Liste des propriétés avec leur type attendu
        const propertyTypes = {
            id: 'number',
            name: 'string',
            isRequestForRaphaelCmaformation: 'boolean',
            isRequestForRaphael: 'boolean',
            isRequestForEparcourspro: 'boolean',
            isRequestForCmonaleternance: 'boolean',
            isRequestForMembership: 'boolean',
            formInfos: 'string',
            structureName: 'string',
            structureAcronym: 'string',
            structureSiret: 'string',
            structureTypeIds: 'array',
            structureApeId: 'number',
            structureAddressStreetDetails: 'string',
            structureAddressStreetDetailsInfos: 'string',
            structureAddressStreetNumber: 'string',
            structureAddressStreetNumberIndex: 'string',
            structureAddressStreetTypeId: 'number',
            structureAddressStreetLabel: 'string',
            structureAddressCityPlace: 'string',
            structureAddressCity: 'string',
            structureAddressCityZip: 'string',
            structureAddressCityInsee: 'string',
            structureAddressLongitude: 'number',
            structureAddressLatitude: 'number',
            structureAddressCountryId: 'number',
            structurePhone: 'string',
            structureMobile: 'string',
            structureEmail: 'string',
            structureWebsite: 'string',
            structureActivityDescription: 'string',
            personReferentStructureTitleId: 'number',
            personReferentStructureFirstname: 'string',
            personReferentStructureLastname: 'string',
            personReferentStructureEmail: 'string',
            personReferentStructurePhone: 'string',
            personReferentStructureMobile: 'string',
            personReferentStructureFunctionId: 'number',
            structureOfferTrainingDeclarationNumber: 'string',
            structureOfferTrainingPlace: 'string',
            structureOfferTrainingPlaceAccess: 'string',
            structureOfferAccomodation: 'boolean',
            structureOfferRestoration: 'boolean',
            structureOfferDisabilityReferentFirstname: 'string',
            structureOfferDisabilityReferentLastname: 'string',
            structureOfferDisabilityReferentPhone: 'string',
            structureOfferDisabilityReferentEmail: 'string',
            structureMembershipTeachingActivityPc: 'number',
            structureMembershipCreationDate: 'date',
            structureMembershipWorkforce: 'number',
            structureMembershipCatalogActivityId: 'number',
            isSameReferent: 'boolean',
            personReferentMembershipTitleId: 'number',
            personReferentMembershipFirstname: 'string',
            personReferentMembershipLastname: 'string',
            personReferentMembershipEmail: 'string',
            personReferentMembershipPhone: 'string',
            personReferentMembershipMobile: 'string',
            personReferentMembershipFunctionId: 'number',
        };
        
        // Parcourir chaque propriété de l'objet
        for (let prop in obj) {
            // Vérifier si la propriété est définie dans les types attendus
            if (prop in propertyTypes) {
                // Forcer la sérialisation selon le type attendu
                switch(propertyTypes[prop]) {
                    case 'number':
                        serializedObj[prop] = Number(obj[prop]);
                        break;
                    case 'string':
                        serializedObj[prop] = String(obj[prop]);
                        break;
                    case 'boolean':
                        serializedObj[prop] = Boolean(obj[prop]);
                        break;
                    case 'array':
                        // Vérifier si la valeur est déjà un tableau
                        serializedObj[prop] = Array.isArray(obj[prop]) ? obj[prop] : [];
                        break;
                    case 'date':
                        serializedObj[prop] = new Date(obj[prop]);
                        break;
                    default:
                        // Si le type attendu n'est pas reconnu, conserver la valeur d'origine
                        serializedObj[prop] = obj[prop];
                        break;
                }
            } else {
                // Si la propriété n'est pas définie dans les types attendus, conserver la valeur d'origine
                serializedObj[prop] = obj[prop];
            }
        }
        
        return serializedObj;
    }

        if (isValid) {
            props.lastStep();
            let merged = {...props.structure, ...result}
            props.completeCallback(serializeProperties(merged));
        }
    };


    const onSuggestHandlerFunctions = (text) => {
        setFonction(text);
        setSuggestionsFunctions([]);
    }

    const onSuggestHandlerFunctionsBis = (text) => {
        setFonctionBis(text);
        setSuggestionsFunctions([]);
    }

    const onSuggestHandlerDomaines = (text) => {
        setDomaine(text);
        setSuggestionsDomain([]);
    }

    const onChangeHandlerFunctions = (text) => {
        setSearchFunctions(props.data4);
        let matches = []
        text = text.split('.').join("");
        if (text.length > 0) {
            matches = searchFunctions.filter(user => {
                const regex = new RegExp(`${text}`, "gi");
                return user.name.match(regex)
            })
        }
        setSuggestionsFunctions(matches)
        setFonction(text)
    }

    const onChangeHandlerFunctionsBis = (text) => {
        setSearchFunctions(props.data4);
        let matches = []
        text = text.split('.').join("");
        if (text.length > 0) {
            matches = searchFunctions.filter(user => {
                const regex = new RegExp(`${text}`, "gi");
                return user.name.match(regex)
            })
        }
        setSuggestionsFunctions(matches)
        setFonctionBis(text)
    }


    const onChangeHandlerDomaines = (text) => {
        let matches = []
        text = text.split('.').join("");
        if (text.length > 0) {
            matches = searchDomaines.filter(user => {
                const regex = new RegExp(`${text}`, "gi");
                return user.name.match(regex)
            })
        }
        setSuggestionsDomain(matches)
        setDomaine(text)
    }

    function validatePhoneNumbers() {
        const phone = watch('personReferentStructurePhone');
        const mobile = watch('personReferentStructureMobile');
        return Boolean(phone || mobile) || 'Au moins un des champs téléphones doit être renseigné.';
    }

    function validatePhoneNumbersMembership() {
        const phone = watch('personReferentMembershipPhone');
        const mobile = watch('personReferentMembershipMobile');
        return Boolean(phone || mobile) || 'Au moins un des champs téléphones doit être renseigné.';
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <span className="alert alert-info alert-info-referent">
Le référent de la structure est la personne qui confirme les demandes de compte, attribue les droits d’accès des nouveaux utilisateurs.trices et signale une modification d’information de la structure aux gestionnaires Cap Métiers. Pour en savoir +, consultez  <a href="https://www.cap-metiers.pro/TELECHARGEMENT/4403/_Creer__et__gerer__son__compte__utilisateur__pdf_.pdf" target="_blank">le guide</a>.<br /> <br />

Une fois votre structure référencée, les gestionnaires Cap Métiers créent le compte utilisateur du référent, qui recevra une notification par mail. D’autres utilisateurs pourront ensuite créer leur compte à leur tour.
                    </span>
                </div>
            </div>
            <form onSubmit={handleSubmit(handleLastStep)}>
                <div className="row">
                    <div className="col-md-12">
                        <FormGroup>
                            <Label>Civilité * : </Label>
                            <FormGroup check>
                                <input
                                    {...register("personReferentStructureTitleId", {required: "Ce champ est obligatoire"})}
                                    name="personReferentStructureTitleId"
                                    id="monsieur"
                                    type="radio"
                                    value={monsieur}
                                />
                                {' '}
                                <label  for="monsieur">
                                    Monsieur
                                </label>
                            </FormGroup>
                            <FormGroup check>
                                <input
                                    {...register("personReferentStructureTitleId", {required: "Ce champ est obligatoire"})}
                                    name="personReferentStructureTitleId"
                                    type="radio"
                                    id="madame"
                                    value={madame}
                                />
                                {' '}
                                <label  for="madame">
                                    Madame
                                </label>
                            </FormGroup>
                            {errors.personReferentStructureTitleId && <span className="text-danger">{errors.personReferentStructureTitleId.message}</span>}
                        </FormGroup>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <FormGroup>
                            <Label>Nom * : </Label>
                            <input
                                {...register("personReferentStructureLastname", {required: "Vous devez renseigner ce champ"})}
                                type="text"
                                name="personReferentStructureLastname"
                                className="form-control"
                            />
                            {errors.personReferentStructureLastname && <span className="text-danger">{errors.personReferentStructureLastname.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <FormGroup>
                            <Label>Prénom * : </Label>
                            <input
                                {...register("personReferentStructureFirstname", {required: "Vous devez renseigner ce champ"})}
                                type="text"
                                name="personReferentStructureFirstname"
                                className="form-control"
                            />
                            {errors.personReferentStructureFirstname && <span className="text-danger">{errors.personReferentStructureFirstname.message}</span>}
                        </FormGroup>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <FormGroup>
                            <Label>Email * : </Label>
                            <input
                                {...register("personReferentStructureEmail", {required: "Vous devez renseigner ce champ", pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "veuillez saisir un email valide"
                                    }})}
                                type="email"
                                name="personReferentStructureEmail"
                                className="form-control"
                            />
                            {errors.personReferentStructureEmail && <span className="text-danger">{errors.personReferentStructureEmail.message}</span>}

                        </FormGroup>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <FormGroup>
                            <Label>Fonction *  : </Label><br />
                            <Controller
                                rules={{ required: 'Veuillez choisir une fonction' }}
                                render={(props) => (
                                    <Autocomplete
                                        {...props}
                                        size="small"
                                        id="tags-outlined"
                                        options={searchFunctions}
                                        onInputChange={(event, newInputValue) => {
                                            setFonction(newInputValue);
                                        }}
                                        getOptionLabel={(option) => option.name}
                                        filterSelectedOptions
                                        renderInput={(params) => (
                                            <TextField
                                                {...register('personReferentStructureFunctionId')}
                                                {...params}
                                                label=""
                                                onChange={e => onChangeHandlerFunctions(e.target.value) }
                                                onFocus={e => onChangeHandlerFunctions(e.target.value)}
                                                placeholder="Fonction "
                                            />
                                        )}
                                        onChange={(event, newValue) => {
                                            setFonction(newValue);
                                        }}
                                    />
                                )}
                                name="personReferentStructureFunctionId"
                                control={control}
                            />
                            {errors.personReferentStructureFunctionId && <span className="text-danger">Veuillez choisir une fonction</span>}
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <FormGroup>
                            <Label><sup>(1)</sup> Téléphone fixe  : </Label>
                            <input
                                {...withHookFormMask(register('personReferentStructurePhone', {validate: validatePhoneNumbers}), ['99 99 99 99 99'])}
                                type="text"
                                name="personReferentStructurePhone"
                                className="form-control"
                            />
                            {errors.personReferentStructurePhone && <span className="text-danger">{errors.personReferentStructurePhone.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <FormGroup>
                            <Label><sup>(1)</sup> Téléphone mobile pro  : </Label>
                            <input
                                {...withHookFormMask(register('personReferentStructureMobile', {validate: validatePhoneNumbers}), ['99 99 99 99 99'])}
                                type="text"
                                name="personReferentStructureMobile"
                                className="form-control"
                            />
                            {errors.personReferentStructureMobile && <span className="text-danger">{errors.personReferentStructureMobile.message}</span>}
                        </FormGroup>
                    </div>
                    <div className="d-flex justify-content-center">
                    </div>
                    <p>(1) Au moins un des champs téléphones doit être renseigné.</p>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <fieldset className="fieldset-structure">
                            <legend className="legend-structure">Dans quel(s) but(s) souhaitez-vous référencer votre structure ? (Plusieurs choix possibles)</legend>
                            <div className="fieldset-wrapper">
                                <FormGroup className="mb-3" >
                                    <Form.Check
                                        {...register('isRequestForRaphaelCmaformation')}
                                        name="isRequestForRaphaelCmaformation"
                                        type="checkbox"
                                        id="isRequestForRaphaelCmaformation"
                                        label="Publier mon offre de formation (Rafael/CMaFormation)"
                                    />
                                    <Form.Check
                                        {...register('isRequestForRaphael')}
                                        name="isRequestForRaphael"
                                        type="checkbox"
                                        id="isRequestForRaphael"
                                        label="Rechercher une session et préinscrire des candidats (Rafael)"
                                    />
                                    <Form.Check
                                        {...register('isRequestForEparcourspro')}
                                        name="isRequestForEparcourspro"
                                        type="checkbox"
                                        id="isRequestForEparcourspro"
                                        label="Avoir accès aux formations du programme de professionnalisation (Parcourspro et e-parcourpro)"
                                    />
                                    <Form.Check
                                        {...register('isRequestForCmonaleternance')}
                                        name="isRequestForCmonaleternance"
                                        type="checkbox"
                                        id="isRequestForCmonaleternance"
                                        label="Trouver ou accompagner des candidats en alternance (CMonAlternance)."
                                    />
                                    <Form.Check
                                        {...register('isRequestForMembership')}
                                        name="isRequestForMembership"
                                        type="checkbox"
                                        id="isRequestForMembership"
                                        label="Adhérer à Cap Métiers Nouvelle-Aquitaine" />
                                </FormGroup>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {
                            !!trainning && (
                                <fieldset className="fieldset-structure">
                                    <legend className="legend-structure">Informations complémentaires sur votre organisme de formation</legend>
                                    <div className="fieldset-wrapper">
                                        <FormGroup className="mb-3" >
                                            <Label>Si vous êtes un organisme de formation situé en dehors de la région Nouvelle-Aquitaine, où réaliserez-vous les sessions de formation ? Hormis les formations réalisées à distance / en FOAD, seules les actions se déroulant en Nouvelle-Aquitaine peuvent être référencées dans le Carif Oref NA.</Label>
                                            <input
                                                {...register('structureOfferTrainingPlace')}
                                                type="text"
                                                name="structureOfferTrainingPlace"
                                                placeholder="Exemples : à Limoges, La Rochelle et Biarritz"
                                                className="form-control"
                                                size="small"
                                            />
                                        </FormGroup>
                                        <FormGroup className="mb-3" >
                                            <Label>N° Déclaration d'activité *</Label>
                                            <input
                                                {...register('structureOfferTrainingDeclarationNumber', {required: 'Vous devez renseigner ce champ'})}
                                                className="form-control"
                                                size="small"
                                                name="structureOfferTrainingDeclarationNumber"
                                                type="text"
                                            />
                                            {errors.structureOfferTrainingDeclarationNumber && <span className="text-danger">{errors.structureOfferTrainingDeclarationNumber.message}</span>}
                                        </FormGroup>
                                        <FormGroup className="mb-3" >
                                            <Label>Accès aux locaux</Label>
                                            <textarea
                                                {...register('structureOfferTrainingPlaceAccess')}
                                                name="structureOfferTrainingPlaceAccess"
                                                className="form-control"
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Proposez-vous une solution d'hébergement pour vos stagiaires ? * : </Label>
                                            <FormGroup check>
                                                <input
                                                    {...register("structureOfferAccomodation", {required: 'Ce champ est obligatoire'})}
                                                    name="structureOfferAccomodation"
                                                    type="radio"
                                                    id="hebergementOK"
                                                    value={true}
                                                />
                                                {' '}
                                                <Label check htmlFor="hebergementOK">
                                                    Oui
                                                </Label>
                                            </FormGroup>
                                            <FormGroup check>
                                                <input
                                                    {...register("structureOfferAccomodation", {required: 'Ce champ est obligatoire'})}
                                                    name="structureOfferAccomodation"
                                                    type="radio"
                                                    id="hebergementNonOk"
                                                    value={false}
                                                />
                                                {' '}
                                                <Label check htmlFor="hebergementNonOk">
                                                    Non
                                                </Label>
                                            </FormGroup>
                                            {errors.structureOfferAccomodation && <span className="text-danger">{errors.structureOfferAccomodation.message}</span>}
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Disposez-vous d'un espace restauration ? * : </Label>
                                            <FormGroup check>
                                                <input
                                                    {...register("structureOfferRestoration", {required: 'Ce champ est obligatoire'})}
                                                    name="structureOfferRestoration"
                                                    type="radio"
                                                    id="restorationOK"
                                                    value={true}
                                                />
                                                {' '}
                                                <Label check htmlFor="restorationOK">
                                                    Oui
                                                </Label>
                                            </FormGroup>
                                            <FormGroup check>
                                                <input
                                                    {...register("structureOfferRestoration", {required: 'Ce champ est obligatoire'})}
                                                    name="structureOfferRestoration"
                                                    type="radio"
                                                    id="restorationNonOK"
                                                    value={false}
                                                />
                                                {' '}
                                                <Label check htmlFor="restorationNonOK">
                                                    Non
                                                </Label>
                                            </FormGroup>
                                            {errors.structureOfferRestoration && <span className="text-danger">{errors.structureOfferRestoration.message}</span>}
                                        </FormGroup>
                                        <fieldset className="fieldset-structure">
                                            <legend className="legend-structure">Référent travailleurs handicapés</legend>
                                            <div className="fieldset-wrapper">
                                                <div className="row">
                                                    <div className="col-md-6 col-sm-12">
                                                        <FormGroup>
                                                            <Label>Nom : </Label>
                                                            <input
                                                                {...register("structureOfferDisabilityReferentLastname")}
                                                                className="form-control"
                                                                type="text"
                                                                name="structureOfferDisabilityReferentLastname"
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <FormGroup>
                                                            <Label>Prénom : </Label>
                                                            <input
                                                                {...register("structureOfferDisabilityReferentFirstname")}
                                                                className="form-control"
                                                                type="text"
                                                                name="structureOfferDisabilityReferentFirstname"
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 col-sm-12">
                                                        <FormGroup>
                                                            <Label>Email : </Label>
                                                            <input
                                                                {...register("structureOfferDisabilityReferentEmail", {pattern: {
                                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                        message: "veuillez saisir un email valide"
                                                                    }})}
                                                                className="form-control"
                                                                type="email"
                                                                name="structureOfferDisabilityReferentEmail"
                                                            />
                                                            {errors.structureOfferDisabilityReferentEmail && <span className="text-danger">{errors.structureOfferDisabilityReferentEmail.message}</span>}
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-md-6 col-sm-12">
                                                        <FormGroup>
                                                            <Label>Téléphone : </Label>
                                                            <input
                                                                {...withHookFormMask(register('structureOfferDisabilityReferentPhone'), ['99 99 99 99 99'])}
                                                                type="text"
                                                                className="form-control"
                                                                name="structureOfferDisabilityReferentPhone"
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    </div>
                                </fieldset>
                            )
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {!!adherer && (
                            <fieldset className="fieldset-structure">
                                <legend className="legend-structure">Informations complémentaires concernant votre structure si vous souhaitez adhérer par la suite</legend>
                                <div className="fieldset-wrapper">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                            <Label>Date de création de votre structure * : </Label>
                                            <FormGroup>
                                                <input
                                                    {...register("structureMembershipCreationDate", {required: 'Ce champ est obligatoire'})}
                                                    type="date"
                                                    name="structureMembershipCreationDate"
                                                    className="form-control"
                                                    placeholder="Choisir une date"
                                                    min={"1700-01-01"}
                                                    max={yesterday.toISOString().split("T")[0]}
                                                />
                                                {errors.structureMembershipCreationDate && <span className="text-danger">{errors.structureMembershipCreationDate.message}</span>}
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <FormGroup>
                                                <Label>Effectif *</Label>
                                                <input
                                                    {...register("structureMembershipWorkforce", {required: 'Ce champ est obligatoire'})}
                                                    type="number"
                                                    min={0}
                                                    className="form-control"
                                                    size="small"
                                                    name="structureMembershipWorkforce"
                                                />
                                                {errors.structureMembershipWorkforce && <p className="text-danger">{errors.structureMembershipWorkforce.message}</p>}
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12">
                                            <FormGroup>
                                                <Label>Domaine d'activité principal *</Label>

                                                <select
                                                    name="structureMembershipCatalogActivityId"
                                                    {...register("structureMembershipCatalogActivityId", {required: 'Veuillez sélectionner un élément'})}
                                                    placeholder="Sélectionner..."
                                                    className="form-select"
                                                    aria-label="Default select example">
                                                    <option value="" selected disabled>Sélectionner...</option>
                                                    {props.data5 && props.data5.map((suggestion, i) =>
                                                        <option key={i} value={suggestion.id}>{suggestion.name}</option>
                                                    )}
                                                </select>
                                                {errors.structureMembershipCatalogActivityId && <p className="text-danger">{errors.structureMembershipCatalogActivityId.message}</p>}
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-6 col-sm-12">
                                            <FormGroup>
                                                <Label>Pourcentage de votre activité formation *</Label>
                                                <input
                                                    {...register("structureMembershipTeachingActivityPc", {required: 'Ce champ est obligatoire'})}
                                                    type="number"
                                                    min={0}
                                                    name="structureMembershipTeachingActivityPc"
                                                    className="form-control"
                                                    size="small"
                                                />
                                                {errors.structureMembershipTeachingActivityPc && <p className="text-danger">{errors.structureMembershipTeachingActivityPc.message}</p>}
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <FormGroup>
                                                <Label>Est-ce le même contact que le référent de la structure ? * : </Label>
                                                <FormGroup >
                                                    <input
                                                        {...register("isSameReferent", {required: 'Ce champ est obligatoire'})}
                                                        name="isSameReferent"
                                                        type="radio"
                                                        value={true}
                                                        id="memeReferent"
                                                    />
                                                    {' '}
                                                    <Label check htmlFor="memeReferent">
                                                        Oui
                                                    </Label>
                                                    <input
                                                        {...register("isSameReferent", {required: 'Ce champ est obligatoire'})}
                                                        name="isSameReferent"
                                                        type="radio"
                                                        value={false}
                                                        id="pasMemeReferent"
                                                    />
                                                    {' '}
                                                    <Label check htmlFor="pasMemeReferent">
                                                        Non
                                                    </Label>
                                                </FormGroup>
                                                {errors.isSameReferent && <p className="text-danger">{errors.isSameReferent.message}</p>}
                                            </FormGroup>
                                        </div>
                                    </div>
                                    { (moreDetail === "false") && (
                                        <fieldset className="fieldset-structure">
                                            <legend className="legend-structure">Référent adhésion</legend>
                                            <div className="fieldset-wrapper">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <Label>
                                                                    Civilité * :
                                                                </Label>
                                                                <FormGroup check>
                                                                    <input
                                                                        {...register("personReferentMembershipTitleId", {required: "Ce champ est obligatoire"})}
                                                                        name="personReferentMembershipTitleId"
                                                                        id="monsieurId"
                                                                        type="radio"
                                                                        value={monsieur}
                                                                    />
                                                                    {' '}
                                                                    <label  for="monsieurId">
                                                                        Monsieur
                                                                    </label>
                                                                </FormGroup>
                                                                <FormGroup check>
                                                                    <input
                                                                        {...register("personReferentMembershipTitleId", {required: "Ce champ est obligatoire"})}
                                                                        name="personReferentMembershipTitleId"
                                                                        type="radio"
                                                                        id="madameId"
                                                                        value={madame}
                                                                    />
                                                                    {' '}
                                                                    <label  for="madameId">
                                                                        Madame
                                                                    </label>
                                                                </FormGroup>
                                                                {errors.personReferentMembershipTitleId && <span className="text-danger">{errors.personReferentMembershipTitleId.message}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 col-sm-12">
                                                                <FormGroup>
                                                                    <Label>Nom du résponsable * : </Label>
                                                                    <input
                                                                        {...register("personReferentMembershipLastname", {required: "Ce champ est obligatoire"})}
                                                                        type="text"
                                                                        name="personReferentMembershipLastname"
                                                                        className="form-control"
                                                                    />
                                                                    {errors.personReferentMembershipLastname && <p className="text-danger">{errors.personReferentMembershipLastname.message}</p>}
                                                                </FormGroup>
                                                            </div>
                                                            <div className="col-md-6 col-sm-12">
                                                                <FormGroup>
                                                                    <Label>Prénom du responsable * : </Label>
                                                                    <input
                                                                        {...register("personReferentMembershipFirstname", {required: "Ce champ est obligatoire"})}
                                                                        type="text"
                                                                        name="personReferentMembershipFirstname"
                                                                        className="form-control"
                                                                    />
                                                                    {errors.personReferentMembershipFirstname && <p className="text-danger">{errors.personReferentMembershipFirstname.message}</p>}
                                                                </FormGroup>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 col-sm-12">
                                                                <FormGroup>
                                                                    <Label for="exampleSelect">
                                                                        Fonction * :
                                                                    </Label>
                                                                    <Controller
                                                                        rules={{ required:  'Veuillez choisir une fonction' }}
                                                                        render={(props) => (
                                                                            <Autocomplete
                                                                                {...props}
                                                                                size="small"
                                                                                id="tags-outlined"
                                                                                options={searchFunctions}
                                                                                onInputChange={(event, newInputValue) => {
                                                                                    setFonctionBis(newInputValue);
                                                                                }}
                                                                                getOptionLabel={(option) => option.name}
                                                                                filterSelectedOptions
                                                                                renderInput={(params) => (
                                                                                    <TextField
                                                                                        {...register('personReferentMembershipFunctionId')}
                                                                                        {...params}
                                                                                        label=""
                                                                                        onChange={e => onChangeHandlerFunctionsBis(e.target.value) }
                                                                                        onFocus={e => onChangeHandlerFunctionsBis(e.target.value)}
                                                                                    />
                                                                                )}
                                                                                onChange={(event, newValue) => {
                                                                                    setFonctionBis(newValue);
                                                                                }}
                                                                            />
                                                                        )}
                                                                        name="personReferentMembershipFunctionId"
                                                                        control={control}
                                                                    />
                                                                    {errors.personReferentMembershipFunctionId && <span className="text-danger">Veuillez choisir une fonction</span>}

                                                                </FormGroup>
                                                            </div>
                                                            <div className="col-md-6 col-sm-12">
                                                                <FormGroup>
                                                                    <Label>Courriel * : </Label>
                                                                    <input
                                                                        {...register("personReferentMembershipEmail", {required: 'Ce champ est obligatoire', pattern: {
                                                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                                message: "veuillez saisir un email valide"
                                                                            } })}
                                                                        className="form-control"
                                                                        type="email"
                                                                        name="personReferentMembershipEmail"
                                                                    />
                                                                    {errors.personReferentMembershipEmail && <span className="text-danger">Ce champ est obligtoire</span>}
                                                                </FormGroup>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md-6 col-sm-12">
                                                                <FormGroup>
                                                                    <Label><sup>(1)</sup> Téléphone fixe du responsable : </Label>
                                                                    <input
                                                                        {...withHookFormMask(register('personReferentMembershipPhone', {validate: validatePhoneNumbersMembership}), ['99 99 99 99 99'])}
                                                                        className="form-control"
                                                                        type="text"
                                                                        name="personReferentMembershipPhone"
                                                                    />
                                                                    {errors.personReferentMembershipPhone && <span className="text-danger">{errors.personReferentMembershipPhone.message}</span>}
                                                                </FormGroup>
                                                            </div>
                                                            <div className="col-md-6 col-sm-12">
                                                                <FormGroup>
                                                                    <Label><sup>(1)</sup> Téléphone mobile du responsable : </Label>
                                                                    <input
                                                                        className="form-control"
                                                                        {...withHookFormMask(register('personReferentMembershipMobile', {validate: validatePhoneNumbersMembership}), ['99 99 99 99 99'])}
                                                                        type="text"
                                                                        name="personReferentMembershipMobile"
                                                                    />
                                                                    {errors.personReferentMembershipMobile && <span className="text-danger">{errors.personReferentMembershipMobile.message}</span>}
                                                                </FormGroup>
                                                            </div>
                                                            <div className="d-flex justify-content-center">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </fieldset>
                                    )

                                    }

                                </div>
                            </fieldset>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="form-check mt-4">
                            <input
                                {...register('final_check', {required: 'Merci de cocher cette case avant de valider'})}
                                className="form-check-input"
                                name="final_check"
                                id="final_check"
                                type="checkbox"/>
                            <Label for="final_check">En envoyant ce formulaire, j'accepte que les informations saisies soient exploitées à usage exclusif de contact pour traitement de ma demande. *</Label><br />
                            {errors.final_check && <span className="text-danger">{errors.final_check.message}</span>}
                        </div>
                        <hr />
                        <div>
                            <p className="text-fin-form">
                                Un gestionnaire Cap Métiers va traiter votre demande de référencement de structure. Le référent indiqué dans le formulaire recevra ses identifiants temporaires par mail.
                                Les informations recueillies dans ce formulaire font l’objet d’un traitement informatique consistant à répondre à votre demande de création de structure. Cap Métiers Nouvelle-Aquitaine est seule destinatrice de ces données. Celles-ci sont conservées uniquement le temps de traitement de votre demande.
                                Dans la mesure où cette demande serait acceptée, ces données seront alors stockées dans nos systèmes d’information pour la gestion de votre structure et visibles dans l'annuaire des structures de Cap Métiers (accessible uniquement via un compte utilisateur). Pour les organismes de formation, vos données de structure seront transmises aux organismes officiels dès publication de votre offre de formation sur notre plateforme RAFAEL.
                            </p>
                            <br />
                            <p className="text-fin-form">
                                Conformément à la loi « informatique et libertés », vous pouvez exercer votre droit d'accès aux données vous concernant et les faire rectifier ou effacer en envoyant votre demande par <a href="mailto:offre@cap-metiers.pro">mail</a>.
                            </p>
                        </div>
                    </div>
                </div>
                <br />
                <ActionButtons {...props} previousStep={previous} lastStep={handleLastStep} />
            </form>
        </div>
    );
};

const HorizontalLinearStepper = () => {
    const [stepWizard, setStepWizard] = useState(null);
    const [loading, setLoading] = React.useState(false);
    const [user, setUser] = useState({});
    const [structure, setStructure] = useState({});
    const [activeStep, setActiveStep] = useState(0);
    const [data1, setData1] = useState([]);
    const [data2, setData2] = useState([]);
    const [data3, setData3] = useState([]);
    const [data4, setData4] = useState([]);
    const [data5, setData5] = useState([]);

    const [show, setShow] = useState(false);
    const [displayMessage, setDisplayMessage] = useState('');
    const [displayTitle, setDisplayTitle] = useState('');


    const handleCloseModal = () => {
        setShow(false);
        window.location.assign('https://www.cap-metiers.pro/');
    }
    const handleShowModal = () => setShow(true);

    useEffect(() => {
        document.title = "Demande de référencement - CAP Métiers Nouvelle Aquitaine";

        if (activeStep === 1) {
            axios.get(`${process.env.REACT_APP_API_SOURCE}/api/v1.0/ResPartnerOdoo/Catalog/ape`)
                .then(response => setData1(response.data))
                .catch(error => console.error(error));

            axios.get(`${process.env.REACT_APP_API_SOURCE}/api/v1.0/ResPartnerOdoo/Catalog/street_type`)
                .then(response => setData2(response.data))
                .catch(error => console.error(error));

            axios.get(`${process.env.REACT_APP_API_SOURCE}/api/v1.0/ResPartnerOdoo/Catalog/structure_type`)
                .then(response => setData3(response.data))
                .catch(error => console.error(error));
        }

        if (activeStep === 2) {
            axios.get(`${process.env.REACT_APP_API_SOURCE}/api/v1.0/ResPartnerOdoo/Catalog/functions`)
                .then(response => setData4(response.data))
                .catch(error => console.error(error));

            axios.get(`${process.env.REACT_APP_API_SOURCE}/api/v1.0/ResPartnerOdoo/Catalog/activities`)
                .then(response => setData5(response.data))
                .catch(error => console.error(error));
        }
    }, [activeStep]);


    const assignStepWizard = (instance) => {
        setStepWizard(instance);
    };
    const assignUser = (val) => {
        setUser((user) => ({
            ...user,
            ...val
        }));
    };

    const assignStructure = (val) => {
        setStructure((structure) => ({
            ...structure,
            ...val
        }));
    };

    const handleStepChange = (e) => {
        setActiveStep(e.activeStep - 1);
    };

    const handleComplete = async (merged) => {

        // using await to make sure the data is fetched before continuing
        handleShowModal();
        setLoading(true);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_SOURCE}/api/v1.0/ResPartnerOdoo/request_referencing`, merged);
            // do something with the response
            //console.log(response);
            await wait(2000);
            setLoading(false);
            if (response.status === 200 && response.data.succeed === true) {
                setDisplayTitle("Votre demande de référencement a bien été envoyée !");
            } else {
                setDisplayTitle("Une erreur est survenue lors de l'envoi de votre demande ");
                setDisplayMessage("Une erreur est survenue lors de l'envoi de votre demande. Nous vous invitons à contacter le Pôle Offre de Formation à l'adresse <a href='mailto:tus@cap-metiers.pro'>tus@cap-metiers.pro</a> en indiquant le nom et le SIRET de votre structure. Nous reviendrons vers vous au plus vite. Veuillez nous excuser pour la gêne occasionnée")
            }
        } catch (error) {
            setLoading(false);
            setDisplayTitle("Une erreur est survenue lors de l'envoi de votre demande ");
            setDisplayMessage("Nous vous invitons à contacter le Pôle Offre de Formation à l'adresse <a href='mailto:tus@cap-metiers.pro'>tus@cap-metiers.pro</a> en indiquant le nom et le SIRET de votre structure. Nous reviendrons vers vous au plus vite. Veuillez nous excuser pour la gêne occasionnée")
        }
    };

    return (
        <div className="container">
            <div className="row">
                <div className="col-12" >
                    <div className="logo-titre mt-2">
                        <img src="/logo-cap-metiers.png" alt="Logo Cap Métiers Nouvelle-Aquitaine"/>
                        <h1>Référencez votre structure </h1>
                    </div>
                    {/* <Button className="mb-3" href="/" variant="light"><KeyboardBackspace /></Button>{' '} */}
                </div>
            </div>
            <div className="row">
                <div className="col-12" >
                    <p>Cette étape est préalable à la création d'un compte sur le portail web de Cap Métiers Nouvelle-Aquitaine et vous permettra de figurer dans notre annuaire.
                        <br /><br />Pour toute question, <a href="mailto:tus@cap-metiers.pro?subject=%5BR%C3%A9f%C3%A9rencement%20structure%5D%20Demande%20d'information%20"> vous pouvez nous contacter.</a></p>
                    <span className="">Les champs marqués d'une * sont obligatoires.</span>
                </div>
            </div>
            <Stepper
                styleConfig={
                    {
                        activeBgColor: '#3f1263',
                        activeTextColor: '#fff',
                        inactiveBgColor: '#cacaca',
                        inactiveTextColor: '#6c757d',
                        completedBgColor: '#3f1263',
                        completedTextColor: 'white',
                    }
                }
                activeStep={activeStep}             >
                <Step label="Indiquer votre SIREN/SIRET:" />
                <Step label="Coordonnées de votre structure" />
                <Step label="Identification du référent de la structure" />
            </Stepper>
            <StepWizard instance={assignStepWizard} onStepChange={handleStepChange}>
                <One  userCallback={assignUser} />
                <Two data1={data1} data2={data2} data3={data3} structure={structure} user={user} structureCallback={assignStructure} />
                <Three data4={data4} data5={data5} structure={structure} structureCallback={assignStructure} user={user} completeCallback={handleComplete} />
            </StepWizard>
            <Modal
                show={show}
                onHide={handleCloseModal}
                backdrop="static"
                keyboard={false}
                centered
            >

                <Modal.Body>
                    <div className="d-flex justify-content-center">
                        <Fade
                            in={loading}
                            style={{
                                transitionDelay: loading ? '800ms' : '0ms',
                            }}
                            unmountOnExit
                        >
                            <CircularProgress color="secondary" />
                        </Fade>
                    </div>
                    <div>
                        <h3 className="centred-title">
                            {displayTitle}
                        </h3>
                        <p className="help-message-error" dangerouslySetInnerHTML={{ __html: displayMessage }} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex justify-content-center">
                        <Button variant="secondary" onClick={handleCloseModal}>
                            Fermer
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default HorizontalLinearStepper;
